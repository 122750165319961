import React from 'react'
import Link from 'gatsby-link'
import Layout from '../components/Layout';

import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Header from '../components/HeaderPosts';

const Tags =({ pageContext }) => {
  const { posts, tagName } = pageContext

  if (posts){
    return (
      <Layout>
      <Header/>

      <section id="head-post" className="about-section about-section-2 text-left">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto pad-me pad-me-2">
              <h2 className="mb-0">Posts about {tagName}</h2>
            </div>
          </div>
        </div>
      </section>

        <div>
          <div className="blog-posts blog-posts-2 pad-o-matic">
            <div className="container pad-o-wan">

                {posts.map(post =>{
                return (

                <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
                <div className="col-lg-8 pcard2">
                  <a href={post.frontmatter.path}>
                    <div className="blog-post-preview">
                      <h2>{post.frontmatter.title}</h2>
                    </div>
                  </a>
                </div>
                </div>
                )
                })}

            </div>
          </div>
        </div>




    <SocialLinks/>
    <Footer/>
    </Layout>
    )
  }
}




export default Tags
